.btn {
  border: none;
  border-radius: 6;
  text-transform: uppercase;
  box-shadow: 0 2px 2px 2px rgba(132, 94, 209, 0.2);
  cursor: pointer;
  background-position: left;
  background-size: 200%;
  background-image: linear-gradient(30deg, #e9e9e9, #9a9a9a, #000000);
}

.btn:hover {
  transition: 0.6s ease-in-out;
  background-position: right;
}
