.fade-in {
  transition: opacity 1.5s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1.5s ease;
}

.social-media-button:hover {
  transition: 0.6s ease-in-out;
  background-position: right;
  animation: pulse 1s infinite;
}
