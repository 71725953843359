.spacer{
    aspect-ratio: 900/300;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.spacer1 {
    aspect-ratio: 900/100;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.layer1 {
    background-image: url('../assets/straight-layer-one-top.svg');
}

.layer-one-under {
    background-image: url('../assets/straight-layer-one-bottom.svg');
}

.layer-two-top{
    background-image: url('../assets/straight-layer-two-top.svg');
}

.layer-two-bottom{
    background-image: url('../assets/straight-layer-two-bottom.svg');
}

.layer-three-top{
    background-image: url('../assets/straight-layer-three-top.svg');
}

.layer-three-bottom {
    background-image: url('../assets/straight-layer-three-bottom.svg');
}

.black{
    background-color: black;
}


.section{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 500px;
    margin: 100px 20vw;
    background-color: rgb(0, 0, 0);
}

.portfolio-section{
    min-height: 600px;
}

.services-section {
    min-height: 700px;
}

.project-section {
    min-height: 500px;
}

h3{
  font-family: "Press Start 2P", system-ui;
}

.down-arrow-pulse{
    transition: 0.6s ease-in-out;
  background-position: right;
  animation: pulse 1s infinite;
}