.splash-screen {
  min-height: 100vh;
  min-width: 100vw;
  background-color: black;
  opacity: 0.9;
}

.splash-screen-logo {
  height: 200px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.splash-screen-text {
  color: white;
  margin-top: 1% !important;
  opacity: 1;
  animation: pulseText 5s infinite;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    opacity: 0.5;
  }

  70% {
    transform: scale(1.25);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    opacity: 0.7;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    opacity: 0.5;
  }
}

@keyframes pulseText {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    opacity: 0.5;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    opacity: 0.7;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    opacity: 0.5;
  }
}
