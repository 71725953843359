.logo {
  position: absolute;
  height: 65px;
  margin-top: 0.5%;
}

/* .logo-text {
  margin-left: 1.5%;
  position: absolute;
  height: 150px;
} */

.logo-text{
  margin-left: 1.5rem;
  font-size: 1.2rem;
  font-family: "Press Start 2P", system-ui;
}

.logo-icon{
  font-size: 5000px;
}