.nav-link{
    color: #bdaefe;
}

.nav-link:hover{
    cursor: pointer;
}

.nav-menu-item-line-after{
    background-image: linear-gradient(30deg, #bdaefe, #845ed1, #7a4ed4);
    min-height: 8px;
    transition: 0.3s ease-in-out;
}

.nav-menu-item-line-before{
    background-image: linear-gradient(30deg, #bdaefe, #845ed1, #7a4ed4);
    min-height: 3px;
    transition: 0.3s ease-in-out;
}



/* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
     .navbar-menu-item-offset-1 .navbar-menu-item-offset-2 .navbar-menu-item-offset-4 {
         display: none;
     }

     .navbar-menu-item{
        align-items: center;
     }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
     .navbar-menu-item-offset-1 .navbar-menu-item-offset-2 .navbar-menu-item-offset-3 .navbar-menu-item-offset-4 {
         display: none;
     }

     .navbar-menu-item {
         align-items: center;
     }
 }
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .navbar-menu-item-offset-1 {
        min-width: 300%;
        display: block;
    }

    .navbar-menu-item-offset-2 {
        min-width: 250%;
        display: block;
    }

    .navbar-menu-item-offset-3 {
        min-width: 200%;
        display: block;
    }

    .navbar-menu-item-offset-4 {
        min-width: 150%;
        display: block;
    }

     .navbar-menu-item {
        align-items: flex-end;
     }
 }
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
     .navbar-menu-item-offset-1 {
         min-width: 400%;
         display: block;
     }

     .navbar-menu-item-offset-2 {
         min-width: 332.5%;
         display: block;
     }

     .navbar-menu-item-offset-3 {
         min-width: 266%;
         display: block;
     }

     .navbar-menu-item-offset-4 {
         min-width: 199.5%;
         display: block;
     }
 }
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
     .navbar-menu-item-offset-1 {
         min-width: 532%;
         display: block;
     }

     .navbar-menu-item-offset-2 {
         min-width: 443%;
         display: block;
     }

     .navbar-menu-item-offset-3 {
         min-width: 354%;
         display: block;
     }

     .navbar-menu-item-offset-4 {
         min-width: 265%;
         display: block;
     }
 }
 @media only screen and (min-width: 1600px) {
     .navbar-menu-item-offset-1 {
         min-width: 707%;
         display: block;
     }

     .navbar-menu-item-offset-2 {
         min-width: 590%;
         display: block;
     }

     .navbar-menu-item-offset-3 {
         min-width: 470%;
         display: block;
     }

     .navbar-menu-item-offset-4 {
         min-width: 352%;
         display: block;
     }
 }